import React from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../../../templates/layout/layout';
import Hero from './hero';
import CircleNavigation from './circle-navigation';
import Quotes from './quotes';
import FrontPageNews from './news';
import VimeoVideo from '../../vimeo-video';
import { useCircleNavData } from '../../../hooks/use-circle-nav';

const circleNavContentfulIDs = [
  'fBByaduskgZu8Hrgkc0jb',
  '3YLmA6xQzA0l7abMi6WsXq',
  '4Zf5SyFO7NlfOCY9Upwv8x',
  '3Aiv1vz4I2awVmhcAKmTcM',
];
import { FrontPageGlobalPartnersMap } from '../../global-partners/global-partners';
import { useSiteMetadata } from '../../../hooks/use-site-metadata';
import { useQuotes } from '../../../hooks/use-quotes';

const Index = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  const siteMetadata = useSiteMetadata();

  const navItems = useCircleNavData().filter(lr => {
    return (
      circleNavContentfulIDs.includes(lr.contentful_id) &&
      lr.node_locale === i18n.language
    );
  });

  const quotes = useQuotes().filter(lr => {
    return lr.node_locale === i18n.language;
  });

  return (
    <Layout
      metaTagProps={{
        title: t('seo-index-title'),
        description: t('seo-index-description'),
        lang: i18n.language,
        siteUrl: siteMetadata.siteUrl,
        pathWithoutLanguage: '/',
      }}
    >
      <Hero />
      <VimeoVideo
        src="https://www.youtube.com/embed/8EIgZTdEnFU"
        title={t('watch-video')}
        iframeTitle={t('watch-video-iframe-title')}
      />
      <Quotes quotes={quotes} />
      <CircleNavigation items={navItems} />
      <div className="col-span-full font-bold text-3xl text-center">
        {t('map-title')}
      </div>
      <FrontPageGlobalPartnersMap />
      <FrontPageNews />
    </Layout>
  );
};

export default Index;
